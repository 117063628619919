<template>
  <div class="Table ContractListTable">
    <!--  table toolbar  -->
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input v-if="hasSearch" :value.sync="searchInput" />
        <slot name="left" />
      </div>
      <div class="Table__toolbar-buttons">
        <slot name="right" />
        <add-new-button v-if="hasAddButton" class-name="add-button" :func="onCreateItem" />
      </div>
    </div>
    <!--    table content  -->
    <div class="table-responsive">
      <b-table
        ref="cl-table"
        :items="items"
        :fields="fields"
        sort-by="code"
        thead-class="Table__header"
        tbody-tr-class="Table__row"
        hover
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              {{ data.label }}
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>
        <template #cell(price)="data">
          <div class="cell">
            {{ data.value + '円' }}
          </div>
        </template>
        <template #cell(start_date)="data">
          <div class="cell">
            {{ getDateString(data.value) }}
          </div>
        </template>
        <template #cell(end_date)="data">
          <div class="cell">
            {{ getDateString(data.value) }}
          </div>
        </template>
        <template v-if="hasAddButton" #cell(actions)="data">
          <div class="cell">
            <img
              src="@/assets/icons/ic_edit.svg"
              alt="edit-icon"
              @click.stop.prevent="onEdit(data.item)"
            />
            <img
              src="@/assets/icons/ic_delete.svg"
              alt="delete-icon"
              @click.stop.prevent="onDelete(data.item.id)"
            />
          </div>
        </template>
      </b-table>
    </div>
    <!--   table paging   -->
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>
    <!--    dialog   -->
    <slot name="dialog" />
  </div>
</template>

<script>
import SearchInput from '@/components/commons/common-search-input'
import AddNewButton from '@/components/add-new-button'
import TablePaging from '@/components/commons/common-table-paging'
import SortIcon from '@/components/sort-icon'
import { contractListService } from '@/services/contract-list-service'
import { mapGetters } from 'vuex'
import { stringToDateMonthJapan } from '@/utils/date-utils'
import get from 'lodash-es/get'

export default {
  name: 'ContractListTable',
  components: { SearchInput, AddNewButton, TablePaging, SortIcon },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
    hasAddButton: {
      type: Boolean,
      default: true,
    },
    showEditDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showAddDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showDeleteDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      searchInput: '',
      fields: contractListService.getFields(),
      items: [],
      total: 0,
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      dialogId: 'cl-dialog',
      dialogTitle: '',
      formData: null,
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['mt_contract_type']),
  },
  watch: {
    searchInput(newValue) {
      this.handleValueChange(newValue)
    },
    perPage() {
      this.handleValueChange(this.searchInput)
    },
    currentPage() {
      this.handleValueChange(this.searchInput)
    },
  },
  async created() {
    await this.refreshTable()
  },
  methods: {
    onCreateItem() {
      this.showAddDialog(() => this.refreshTable(true))
    },
    onEdit(rowData) {
      this.showEditDialog(rowData, () => this.refreshTable(true))
    },
    onDelete(id) {
      this.showDeleteDialog(id, () => this.refreshTable(true))
    },
    async refreshTable(isMaster = false) {
      this.searchInput = ''
      const { items, total } = await contractListService.getContract(
        this.perPage,
        this.currentPage,
        this.searchInput,
        true
      )
      this.items = items
      this.total = total
      this.convertValue()
    },
    async handleValueChange(keyword) {
      const { items, total } = await contractListService.getContract(
        this.perPage,
        this.currentPage,
        keyword
      )
      this.items = items
      this.total = total
      this.convertValue()
    },
    convertValue() {
      this.items.map((item) => {
        item.contractTypeName = get(
          this.mt_contract_type.filter((data) => data.id === item.type)[0],
          'name',
          ''
        )
      })
    },
    getDateString(dateValue) {
      return stringToDateMonthJapan(dateValue)
    },
  },
}
</script>
