<template>
  <Layout>
    <page-layout page-titles="契約情報管理" :bread-crumb-items="breadCrumbItems">
      <template #table-content>
        <contract-list-table
          :show-add-dialog="showAddDialog"
          :show-edit-dialog="showEditDialog"
          :show-delete-dialog="showDeleteDialog"
          :has-add-button="!isCustomerUser"
        >
          <template #dialog>
            <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
              <contract-list-modal-form
                :form-data.sync="formData"
                :mode="mode"
                :trigger-validate.sync="triggerValidate"
                :is-valid.sync="isValid"
              />
            </c-modal>
            <delete-confirmation :id="dialogDeleteId" :confirm-delete-func="onDeleteConfirm" />
          </template>
        </contract-list-table>
      </template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import PageLayout from '@/components/commons/common-page-layout'
import CModal from '@/components/commons/common-modal'
import ContractListTable from '@/components/tables/contract-list-table'
import ContractListModalForm from '@/components/forms/contract-list-modal-form'
import DeleteConfirmation from '@/components/modals/delete-confirmation-modal'
import { ALERT_TYPE, FORM_MODE } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import { contractListService } from '@/services/contract-list-service'
import { mapActions, mapGetters } from 'vuex'
import pick from 'lodash-es/pick'

export default {
  name: 'ContractList',
  components: {
    DeleteConfirmation,
    ContractListModalForm,
    ContractListTable,
    Layout,
    PageLayout,
    CModal,
  },
  data() {
    return {
      searchInput: '',
      formData: null,
      dialogId: 'cl-dialog',
      dialogDeleteId: 'del-dialog',
      dialogTitle: '',
      mode: null,
      breadCrumbItems: [
        {
          text: 'SaaS管理',
          href: '/management-service',
        },
        {
          text: '契約情報',
          active: true,
        },
      ],
      dialogTitles: {
        ADD: {
          id: 1,
          text: '契約新規登録',
        },
        EDIT: {
          id: 2,
          text: '契約情報編集',
        },
        DELETE: {
          id: 3,
          text: '',
        },
      },
      onAddOrEditConfirm: () => {
        return {}
      },
      triggerValidate: false,
      isValid: false,
      requiredCreateKeys: ['name', 'subscribe_apps_id', 'price', 'end_date', 'start_date', 'type'],
      requiredEditKeys: [
        'id',
        'name',
        'subscribe_apps_id',
        'price',
        'end_date',
        'start_date',
        'type',
      ],
      buttonText: '',
    }
  },
  computed: {
    ...mapGetters('user', ['isCustomerUser']),
    dialogButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: this.buttonText,
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    showEditDialog(rowData, editSuccessCallback) {
      this.mode = FORM_MODE.EDIT
      this.dialogTitle = this.dialogTitles.EDIT.text
      this.buttonText = '更新'
      this.formData = { ...rowData }
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            this.callbackFn = editSuccessCallback
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.$bvModal.show(this.dialogId)
    },

    showAddDialog(createSuccessCallback) {
      this.mode = FORM_MODE.ADD
      this.dialogTitle = this.dialogTitles.ADD.text
      this.formData = null
      this.buttonText = '保存'
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            const formData = pick(this.formData, this.requiredCreateKeys)
            const { success, data } = await contractListService.createContract(formData)
            if (!success) {
              const messages = this.getErrorAlert(data)
              messages.forEach((message) => {
                this.displayAlert({
                  type: ALERT_TYPE.ERROR,
                  messages: message,
                })
              })
              return
            }
            createSuccessCallback && createSuccessCallback()
            this.$bvModal.hide(this.dialogId)
            this.displayAlert({
              type: ALERT_TYPE.SUCCESS,
              messages: MESSAGES.COMMON.MSG01,
            })
          }
        }, 150)
      }
      this.$bvModal.show(this.dialogId)
    },

    showDeleteDialog(deleteId, deleteSuccessCallback) {
      this.deleteId = deleteId
      this.callbackFn = deleteSuccessCallback
      this.dialogTitle = this.dialogTitles.DELETE.text
      this.$bvModal.show(this.dialogDeleteId)
    },

    async onDeleteConfirm() {
      const result = await contractListService.deleteContract(this.deleteId)
      if (result) {
        // delete success
        this.callbackFn && (await this.callbackFn())
        this.displayAlert({
          type: ALERT_TYPE.SUCCESS,
          messages: MESSAGES.COMMON.MSG05,
        })
      }
      this.$bvModal.hide(this.dialogDeleteId)
    },
    async onUpdateConfirm() {
      const formData = pick(this.formData, this.requiredEditKeys)
      const { success, data } = await contractListService.editContract(formData)
      if (!success) {
        const messages = this.getErrorAlert(data)
        messages.forEach((mes) => {
          this.displayAlert({
            type: ALERT_TYPE.ERROR,
            messages: mes,
          })
        })
        return
      }
      this.callbackFn && this.callbackFn()
      this.$bvModal.hide(this.dialogId)
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
    },
    getErrorAlert(data) {
      const result = []
      if (data.name) {
        result.push(MESSAGES.CUSTOMER_MT.KH02)
      }
      if (data.time_line) {
        result.push(MESSAGES.CUSTOMER_MT.KH03)
      }
      if (result.length === 0) {
        result.push(MESSAGES.COMMON.MSG15)
      }
      return result
    },
  },
}
</script>
